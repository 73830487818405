import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
	{
		path: "/",
		name: "Layout",
		component: Layout,
		children: [
			{
				//默认 显示home组件
				path: "",
				name: "Home",
				component: () => import("../views/home/index.vue")
			},
			{
				//默认 显示home组件
				path: "news",
				name: "news",
				component: () => import("../views/news/index.vue")
			}
		],
	},
]




const createRouter = () => new Router({
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router